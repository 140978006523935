import React from "react";
import { Container, Text, Image } from "@atoms";

const TwoColumnCopy = ({ heading, copy, image }) => {
  return (
    <Container>
      <div className="flex flex-col gap-5">
        <Text variant="h2">{heading}</Text>
        <div className="columns-1 gap-10 md:columns-2">
          <Text richText>{copy}</Text>
        </div>
        {!!image && (
          <div className="relative mt-5 h-24 w-24 overflow-hidden rounded-full shadow-md md:h-36 md:w-36">
            <Image image={image} fill className="rounded-full" />
          </div>
        )}
      </div>
    </Container>
  );
};

TwoColumnCopy.defaultProps = {};

export default TwoColumnCopy;
